import leveljs from "level-js";
import levelup from "levelup";
import { generateId } from "./idGenerator";

var db = levelup(leveljs("config"));

export const KEY_PROFILES = "PROFILES";
export const KEY_THEME = "theme";
export const KEY_TEST_CONFIG = "testConfig";
export const KEY_SCRIPT_SETS = "script_sets";
export const KEY_DEVICE_SET = "device_set";
export const KEY_FLEX_WROKSPACE = "flexws";

export const DB_KEY_COMMANDER_ID = "COMMANDER_ID";

const PROFILE_RELATED_KEYS = [
  KEY_THEME,
  KEY_TEST_CONFIG,
  KEY_SCRIPT_SETS,
  KEY_DEVICE_SET,
  KEY_FLEX_WROKSPACE,
];

let currentProfile: Profile | null = null;

export interface Profile {
  key: string;
  name: string;
}

function keyToProfiledKey(profile: Profile | null, key: string): string {
  return `${profile ? profile.key + "_" : ""}${key}`;
}

export function getValueForProfile<T>(
  profile: Profile | null,
  key: string,
  defaultValue: T | null = null
) {
  const profiledKey = keyToProfiledKey(profile, key);
  if (window.localStorage) {
    const object = window.localStorage.getItem(profiledKey);
    if (object) {
      return JSON.parse(object);
    }
  }
  return defaultValue;
}

export function getValueForCurrentProfile<T>(
  key: string,
  defaultValue: T | null = null
): T | null {
  return getValueForProfile(currentProfile, key, defaultValue);
}

export function setValueForProfile<T>(
  profile: Profile | null,
  key: string,
  value: T
) {
  const profiledKey = keyToProfiledKey(profile, key);
  if (window.localStorage) {
    window.localStorage.setItem(profiledKey, JSON.stringify(value));
  }
}

export function setValueForCurrentProfile<T>(key: string, value: T) {
  setValueForProfile(currentProfile, key, value);
}

export function delValueForProfile(profile: Profile | null, key: string) {
  const profiledKey = keyToProfiledKey(profile, key);
  if (window.localStorage) {
    window.localStorage.removeItem(profiledKey);
  }
}

export function delValueForCurrentProfile(key: string) {
  delValueForProfile(currentProfile, key);
}

export function getProfiles(): Profile[] {
  const profiles: Profile[] = getValueForProfile(null, KEY_PROFILES, []);
  return profiles;
}

export function getCurrentProfile(): Profile | null {
  return currentProfile;
}

export function setCurrentProfile(profile: Profile | null) {
  currentProfile = profile;
}

export function createProfile(name: string, key?: string): Profile {
  key = key || generateId();
  const oldProfiles = getProfiles();
  while (oldProfiles.find((x) => x.key == key)) {
    key = key || generateId();
  }
  const newProfile: Profile = {
    key,
    name,
  };
  setValueForProfile(null, KEY_PROFILES, [...oldProfiles, newProfile]);
  return newProfile;
}

export function removeProfile(profile: Profile) {
  const oldProfiles = getProfiles();
  const newProfiles = oldProfiles.filter((x) => x.key != profile.key);
  PROFILE_RELATED_KEYS.forEach((key) => {
    delValueForProfile(profile, key);
  });
  setValueForProfile(null, KEY_PROFILES, newProfiles);
}

export async function migrate() {
  try {
    const oldCommanderKey = String(await db.get(DB_KEY_COMMANDER_ID));
    if (oldCommanderKey) {
      await db.del(DB_KEY_COMMANDER_ID);
      const profile = createProfile("default", oldCommanderKey.toUpperCase());
      PROFILE_RELATED_KEYS.forEach((key) => {
        const oldValue = getValueForProfile(null, key, null);
        setValueForProfile(profile, key, oldValue);
        delValueForProfile(null, key);
      });
    }
  } catch {}
}
