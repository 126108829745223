import { init as initElements } from "./models/ElementDefinitionDict";
import { init as initEvents } from "./models/EventDefinitionDict";
import { init as initMessages } from "./models/MessageDefinitionDict";
import * as persistence from "./persistence";

async function start() {
  await Promise.all([initElements, initEvents, initMessages]);
  await persistence.migrate();
  let profiles = persistence.getProfiles();
  if (profiles.length == 0) {
    persistence.createProfile("default");
    profiles = persistence.getProfiles();
  }

  const reactRootElement = document.getElementById("root");
  const profilesSelectorElement = document.getElementById("profiles-selector");
  const profilesListElement = document.getElementById("profiles-list");
  const createProfileTextBox = document.getElementById(
    "create-profile-textbox"
  ) as HTMLInputElement;
  const createProfileButton = document.getElementById("create-profile-button");

  const onProfileClick = (profile: persistence.Profile) => {
    persistence.setCurrentProfile(profile);
    console.log(persistence.getCurrentProfile());
    profilesSelectorElement!!.style.display = "none";
    reactRootElement!!.style.display = "block";
    import("./init");
    import("./mqttConnection");
  };

  const appendProfileListItem = (profile: persistence.Profile) => {
    const listItem = document.createElement("div");
    const profileNameText = document.createElement("div");
    profileNameText.innerHTML = `${profile.name} [${profile.key}]`;
    profileNameText.onclick = () => onProfileClick(profile);
    listItem.append(profileNameText);
    const removeIcon = document.createElement("i");
    removeIcon.classList.add("far");
    removeIcon.classList.add("fa-trash-alt");
    removeIcon.onclick = () => {
      const confirmed = window.confirm(
        `Are you sure you want to delete profile "${profile.name}"?`
      );
      if (confirmed) {
        persistence.removeProfile(profile);
        profilesListElement!!.removeChild(listItem);
      }
    };
    listItem.append(removeIcon);
    profilesListElement!!.appendChild(listItem);
  };

  profiles.forEach(appendProfileListItem);

  createProfileButton!!.onclick = () => {
    const name = createProfileTextBox!!.value!.trim().toLowerCase();
    console.log(name);
    if (name) {
      const newProfile = persistence.createProfile(name);
      appendProfileListItem(newProfile);
      createProfileTextBox!!.value = "";
    }
  };
}

start();
