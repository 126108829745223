const sheetUrl = "https://files.azenqos.com/public/element_ids.csv";

export enum DataType {
  Unsupported,
  Integer,
  String,
  Date,
  Double,
}

export function getDataTypeDefaultNumberFormat(
  dataType: DataType
): string | undefined {
  switch (dataType) {
    case DataType.Unsupported:
      return undefined;
    case DataType.Integer:
      return "0";
    case DataType.String:
      return undefined;
    case DataType.Date:
      return undefined;
    case DataType.Double:
      return "0.00";
  }
  return undefined;
}

export interface ElementDefinition {
  id: number;
  name: string;
  dataType: DataType;
  minValue: number;
  maxValue: number;
  nArg: number;
  title: string;
  expiry: number;
  definition: string;
}

export interface ElementDefinitionDict {
  [index: string]: ElementDefinition;
}

export const ELEMENT_IDS: { [index: string]: number } = {};
export const ELEMENT_DEFINITION_DICT: ElementDefinitionDict = {};

const COLUMN_ID = 0;
const COLUMN_NAME = 1;
const COLUMN_DATA_TYPE = 4;
const COLUMN_MIN_VALUE = 6;
const COLUMN_MAX_VALUE = 7;
const COLUMN_N_ARG = 8;
const COLUMN_TITLE = 9;
const COLUMN_EXPIRY = 10;
const COLUMN_DEFINITION = 11;

import Papa from "papaparse";

const levelup = require("levelup");
const leveljs = require("level-js");
const db = levelup(leveljs("config"));

export const downloadFromSheet = async (resolve: any, reject: any) => {
  let csv = await (await fetch(sheetUrl)).text();
  Papa.parse(csv, {
    step: function (row: any) {
      const id = row.data[COLUMN_ID];
      if (!isNaN(id)) {
        const name: string = row.data[COLUMN_NAME];
        const dataType: DataType = parseDataType(row.data[COLUMN_DATA_TYPE]);
        const minValue: number = parseNumber(row.data[COLUMN_MIN_VALUE]);
        const maxValue: number = parseNumber(row.data[COLUMN_MAX_VALUE]);
        const nArg: number = parseNumber(row.data[COLUMN_N_ARG], 1);
        let title: string = row.data[COLUMN_TITLE];
        const expiry: number = parseNumber(row.data[COLUMN_EXPIRY]);
        const definition: string = row.data[COLUMN_DEFINITION];
        if (!title || title.trim().length == 0) {
          title = name.replace("_", " ");
        }
        const def: ElementDefinition = {
          id: Number.parseInt(id),
          name,
          dataType,
          minValue,
          maxValue,
          nArg,
          title,
          expiry,
          definition,
        };
        ELEMENT_DEFINITION_DICT[id] = def;
        ELEMENT_IDS[name] = Number.parseInt(id);
      }
    },
    complete: async () => {
      // console.table(ELEMENT_DEFINITION_DICT);
      db.put("ELEMENT_DATE", new Date().getTime());
      db.put("ELEMENT_IDS", JSON.stringify(ELEMENT_IDS));
      db.put(
        "ELEMENT_DEFINITION_DICT",
        JSON.stringify(ELEMENT_DEFINITION_DICT)
      );
      console.log("load elements done from sheet!!");
      resolve();
    },
    error: async (err) => {
      try {
        readFromDB();
        console.log("load elements done from cache!!");
        resolve();
      } catch {
        console.log("load elements error:", err);
        reject();
      }
    },
  });
};

async function readFromDB() {
  const elementIdsJson = JSON.parse(
    new String(await db.get("ELEMENT_IDS")) as string
  );
  const elementDefDictJson = JSON.parse(
    new String(await db.get("ELEMENT_DEFINITION_DICT")) as string
  );
  for (const key in elementIdsJson) {
    const val = elementIdsJson[key];
    ELEMENT_IDS[key] = val;
  }
  for (const key in elementDefDictJson) {
    const val = elementDefDictJson[key];
    ELEMENT_DEFINITION_DICT[key] = val;
  }
}

function parseDataType(value: string): DataType {
  if (value) {
    switch (value.toLowerCase()) {
      case "integer":
        return DataType.Integer;
      case "double":
        return DataType.Double;
      case "string":
        return DataType.String;
      case "datetime":
        return DataType.Date;
    }
  }
  return DataType.Unsupported;
}

function parseNumber(value: string, defaultValue: number = 0): number {
  const num = Number(value);
  if (!value || isNaN(num)) {
    return defaultValue;
  }
  return num;
}

export const init = new Promise(async (resolve, reject) => {
  let lastUpdate = -1;
  try {
    lastUpdate = Number(await db.get("ELEMENT_DATE"));
  } catch {}
  if (
    lastUpdate > 0 &&
    new Date().getTime() - lastUpdate < 1000 * 60 * 60 * 3
  ) {
    console.log("load elements from cache");
    try {
      await readFromDB();
      console.log("load elements done from cache!!");
      resolve();
    } catch {
      await downloadFromSheet(resolve, reject);
    }
  } else {
    console.log("load elements from sheet");
    await downloadFromSheet(resolve, reject);
  }
});
