var levelup = require("levelup");
var leveljs = require("level-js");
var db = levelup(leveljs("config"));

const COMMANDER_ID_DB_KEY = "COMMANDER_ID";

export function generateId(): string {
  // and then just do:
  return [...Array(6)]
    .map((i) => (~~(Math.random() * 36)).toString(36))
    .join("")
    .toUpperCase();
}

let commanderId: string = "";

export async function getId(): Promise<string> {
  try {
    commanderId = String(await db.get(COMMANDER_ID_DB_KEY));
  } catch (e) {
    commanderId = generateId();
    db.put(COMMANDER_ID_DB_KEY, commanderId, function (err: any) {
      err && console.error(err);
    });
  }
  commanderId = commanderId.toUpperCase();
  return commanderId.toUpperCase();
}

export function getCachedId(): string {
  return commanderId;
}
