const sheetUrl = "https://files.azenqos.com/public/message_ids.csv";

interface MessageDefinition {
  id: number;
  name: string;
  title: string;
}

interface MessageDefinitionDict {
  [index: string]: MessageDefinition;
}

export const MESSAGE_IDS: { [index: string]: number } = {};
export const MESSAGE_DEFINITION_DICT: MessageDefinitionDict = {};

const COLUMN_ID = 0;
const COLUMN_NAME = 1;
const COLUMN_TITLE = 2;

import Papa from "papaparse";

const levelup = require("levelup");
const leveljs = require("level-js");
const db = levelup(leveljs("config"));

export const downloadFromSheet = async (resolve: any, reject: any) => {
  let csv = await (await fetch(sheetUrl)).text();
  Papa.parse(csv, {
    step: function (row) {
      const id = row.data[COLUMN_ID];
      if (!isNaN(id)) {
        const name: string = row.data[COLUMN_NAME];
        const title: string = row.data[COLUMN_TITLE];
        const def: MessageDefinition = {
          id: Number.parseInt(id),
          name,
          title,
        };
        MESSAGE_DEFINITION_DICT[id] = def;
        MESSAGE_IDS[name] = Number.parseInt(id);
      }
    },
    complete: function () {
      db.put("MESSAGE_DATE", new Date().getTime());
      db.put("MESSAGE_IDS", JSON.stringify(MESSAGE_IDS));
      db.put(
        "MESSAGE_DEFINITION_DICT",
        JSON.stringify(MESSAGE_DEFINITION_DICT)
      );
      console.log("load messages done from sheet!!");
      resolve();
    },
    error: function (err) {
      try {
        readFromDB();
        console.log("load messages done from cache!!");
        resolve();
      } catch {
        console.log("load messages error:", err);
        reject();
      }
    },
  });
};

async function readFromDB() {
  const messageIdsJson = JSON.parse(
    new String(await db.get("MESSAGE_IDS")) as string
  );
  const messageDefDictJson = JSON.parse(
    new String(await db.get("MESSAGE_DEFINITION_DICT")) as string
  );
  for (const key in messageIdsJson) {
    const val = messageIdsJson[key];
    MESSAGE_IDS[key] = val;
  }
  for (const key in messageDefDictJson) {
    const val = messageDefDictJson[key];
    MESSAGE_DEFINITION_DICT[key] = val;
  }
}

export const init = new Promise(async (resolve, reject) => {
  console.log("load messages");
  let lastUpdate = -1;
  try {
    lastUpdate = Number(await db.get("MESSAGE_DATE"));
  } catch {}
  if (
    lastUpdate > 0 &&
    new Date().getTime() - lastUpdate < 1000 * 60 * 60 * 3
  ) {
    console.log("load messages from cache");
    try {
      await readFromDB();
      console.log("load messages done from cache!!");
      resolve();
    } catch {
      await downloadFromSheet(resolve, reject);
    }
  } else {
    console.log("load messages from sheet");
    await downloadFromSheet(resolve, reject);
  }
});
